.language-flags .flag {
    cursor: pointer;
    margin-left:1.5rem;
    border-radius:10px;
    scale: 1.5;

}

.language-flags .flag:hover {
    scale: 2;
}

.flag.active-flag {
    border: solid 1px var(--primary-color);
    border-radius: 10px;
}

.no-margin-flag {
    margin: 0;
}

@media (max-width: 1024px) {
    .language-flags .flag {
        cursor: pointer;
        margin:3rem;
        
        scale: 3;
    
    }
    
    .language-flags .flag:hover {
        scale: 3.5;
    }
  }
  
  @media (max-width: 768px) {
    .language-flags .flag {
        cursor: pointer;
        margin:1rem;
        
        scale: 1.5;
    
    }
    
    .language-flags .flag:hover {
        scale: 1.5;
    }
  }