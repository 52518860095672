.contact-us {
  height:100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-us-inner-container {
  display: flex;
  margin:30px;
}

.column.left > .contact-us-inner-container {
  height: auto;
}

.addresses {
  display:flex;
  justify-content: space-evenly;
}

.contact-us-form {
  padding:20px;
  background-color: #F7F7FA;
  text-align:left;
  width: 100%;
}


.contact-us button {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-size: large;
  background: none;
  border-radius: 0px;
}


.contact-us button:hover {
  background: none;
  border-bottom: 2px solid var(--primary-color);
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-size:large;
}

.active {
  border-bottom: 2px solid var(--primary-color);
}

a:hover {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

.contact-us-address {
  padding:20px;
  background-color: #F7F7FA;
  text-align:left;
  width: 100%;
}

.address-container {
  font-size:x-large;
}

.column {
  flex: 1;
}

.image-container {
  position: relative;
  width: 100%; /* Adjust as necessary */
  max-width: 300px; /* Set your desired max width */
  height: auto; /* Adjust as necessary */
  overflow: hidden;
}

.img {
  object-fit: contain;
}

.contact-image {
  display: block;
  max-width: 100%;
  height: 100%;
}

/* Diagonal line overlay */

.column.right {
  /* Ensure the column is positioned relative to contain the pseudo-element */
}

.image-background {
  transform: scaleX(-1);
  height:100%;
  width:100%;
  background-size: cover; 
  background-repeat: no-repeat; 

}

.image-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(83deg, #fff0 40%, #ffffff 84%);
  pointer-events: none;
}

/* Mobile Styles */



@media (max-width: 1024px) {
  .column.right {
    display:block;
  }

  .map-container {
    margin: 0;
  }
  
  .addresses {
    display:block;
  }

  .contact-us-inner-container {
    margin: 0px; 
    margin-top:30px;
  }

  .column.center div {
  
    text-align: center;
    margin:1rem;
  }
}

@media (max-width: 768px) {
  .column.right {
    display:block;
  }

  .column.center div {
  
    text-align: center;
    margin:1rem;
  
  }
}
