/* Reset default styles */
input, textarea {
    font-family: inherit;
    font-size: inherit;
    border: none;
    border-bottom: 2px solid #ddd;
    outline: none;
    background-color: transparent;
    border-radius: 0;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    transition: border-color 0.3s ease;
  }
  
  /* Style for focused input */
  input:focus, textarea:focus {
    border-bottom: 2px solid var(--primary-color); /* Change to your desired focus color */
  }
  
  /* Style for placeholder */
  input::placeholder, textarea::placeholder {
    color: #aaa; /* Change to your desired placeholder color */
  }
  
  /* Style for labels */
  label {
    display: block;
    margin-bottom: 5px;
    font-size:large;
  }
  
  /* Style for buttons */
  button {
    padding: 10px 20px;
    background-color: var(--primary-color); /* Change to your desired button color */
    color: #fff; /* Change to your desired button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .form-container button.submitButton {
    padding: 10px 20px;
    background-color: var(--primary-color); /* Change to your desired button color */
    color: #fff; /* Change to your desired button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #9a1616; /* Change to your desired hover color */
  }

  .form-container {
    text-align: center;
    position: relative;
  }

  .success-bubble {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #22C55E;
    background-color: #d4edda;
    color: #22C55E;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }