.rehabguru-iframe {
  height: 100%;
  width: 96%;
  border: 0;
  margin: 20px;
}

.book-appointment {
  margin-top: 20px;
  margin-bottom: -0.3rem;
  height: 220vh;
}

.book-appointment-container {
  display: flex;
  justify-content: space-between;
  /* Adjust spacing between the items */
  align-items: flex-start;
  /* Aligns items at the top */
  gap: 40px;
}

.book-appointment-item {
  display: flex;
  flex-direction: column;
  /* Stacks text above image */
  align-items: center;
  /* Centers content horizontally */
  width: 50%;
  /* Adjusts width to make them sit side by side */
  padding: 20px;
  background-color: #fff;
  margin: 25px;
  margin-top: 30px;
}

.book-appointment-item.email {
  width: 97%;
  padding: 20px;
  margin-left: 25px;
  align-items: normal;
  margin-right: 60px;
}

.book-appointment-item a {
  font-size: medium;
}

.book-appointment-item a:hover {
  font-size: medium;
}

.bookHullButton {
  margin: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Mobile styles */
@media (max-width: 1024px) {
  .rehabguru-iframe {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .book-appointment {
    height: 460vh;
    margin-left: 0;
    margin-right: 0;
  }

  .book-appointment-container {
    flex-direction: column-reverse;
    /* Stack items vertically */
    margin-top: 20px;
  }

  .book-appointment-item {
    width: 100%;
    /* Full width for items */
    margin: 0;
  }

  .book-appointment-item.email {
    margin: 0;
    margin-top: 20px;
    width: 100%;
  }
}


@media (max-width: 768px) {

  .rehabguru-iframe {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .book-appointment {
    height: 560vh;
    margin-left: 0;
    margin-right: 0;
  }

  .book-appointment-container {
    flex-direction: column-reverse;
    /* Stack items vertically */
    margin-top: 20px;
  }

  .book-appointment-item {
    width: 100%;
    /* Full width for items */
    margin: 0;
    
  }

  .book-appointment-item.email {
    margin: 0;
    margin-top: 20px;
    width: 100%;
  }
}