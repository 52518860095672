.blood-tests-container {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Spacing between each section */
    margin: 35px;
}

.blood-tests-section {
    display: grid;
    grid-template-columns: 2fr 1fr; /* Split into two equal columns */
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
    text-align: left;
}

.blood-tests-section-wide {
    display: grid;
    grid-template-columns: 1fr 3fr; /* Split into two equal columns */
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
    text-align: left;
}

.blood-tests-section-wide > .placeholder-image {
    max-width: 400px;
}

.blood-tests-section > .placeholder-image {
    max-width: 500px;
}


.blood-tests-section-center {
    display: block;
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}

.blood-tests-section-center .la {
    text-align: left;
}

.placeholder-image {
    width: 100%;
    height: auto;
    max-width: 300px; /* Restrict maximum image size */
    border-radius: 10px;
}

.text-content {
    padding: 10px 20px;
}

.text-content-center {
    text-align: center;
}

.text-content h2,
.text-content h3 {
    margin-bottom: 15px;
}

.text-content ul {
    list-style: none;
    padding-left: 0;
}

.text-content ul li {
    margin-bottom: 10px;
}

.text-content p {
    margin: 10px 0;
}

.text-content strong {
    font-weight: bold;
}


.brochure_download_button {
    margin: 10px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media (max-width: 1024px) {
    .blood-tests-container {
        margin: 0px; /* Optional: Adjust margin for smaller screens */
    }

    .blood-tests-section {
        grid-template-columns: 1fr; /* Stack sections vertically */
    }

    .blood-tests-section-wide {
        grid-template-columns: 1fr; /* Stack sections vertically */
    }

    .blood-tests-section,
    .blood-tests-section-wide,
    .blood-tests-section-center {
        margin-left: 0; /* Set left margin to 0 */
        margin-right: 0; /* Set right margin to 0 */
    }

    .brochure_download_button {
        margin: 10px 5; /* Set margin to 0 left and right */
    }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
    .blood-tests-container {
        margin: 0px; /* Optional: Adjust margin for smaller screens */
    }

    .blood-tests-section {
        grid-template-columns: 1fr; /* Stack sections vertically */
    }

    .blood-tests-section-wide {
        grid-template-columns: 1fr; /* Stack sections vertically */
    }

    .blood-tests-section,
    .blood-tests-section-wide,
    .blood-tests-section-center {
        margin-left: 0; /* Set left margin to 0 */
        margin-right: 0; /* Set right margin to 0 */
    }

    .brochure_download_button {
        margin: 10px 5; /* Set margin to 0 left and right */
    }
}